var NewsSlider = function(options) {
    
    var that = {
        yearSlider: null,
        newsSlider: null,
        currentYear: '',
        currentNewsPost: ''
    };
        
    var defaults = {
        el: '#nsSlider',
        elY: '#nsYears',
        elN: '#nsNews',
        elB: '#nsNewsPostBody',
        elYearActivator: '.nsYearActivator',
        elNewsActivator: '.nsNewsActivator',
        elNewsPost: '.NewsPost',
        maxYearsToDisplay: 4
        
        //urlManipulator
    };
    
    var opt = $.extend({}, defaults, options);    
    
    /**
     * [init description]
     * @return {[type]} [description]
     */
    that.init = function() {
        if ($(opt.el).length) {
            that.boot();
            activateNewsPostFromUriOrLast();
        }
    };
    
    /**
     * [activateNewsPostFromUriOrLast description]
     * @return {[type]} [description]
     */
    var activateNewsPostFromUriOrLast = function() {
        var cHash = opt.urlManipulator.hash;
        if (cHash !== '') {
            cYear = opt.urlManipulator.hash.substring(0, opt.urlManipulator.hash.indexOf('-'));
            cNewsPost = opt.urlManipulator.hash.substring(5);
            
            setTimeout(function() {
                $(opt.elY).find(opt.elYearActivator).closest('[data-slide-year="' + cYear + '"]').trigger('click');
                setTimeout(function() {
                    $(opt.elN).find(opt.elNewsActivator).closest('[data-slide-alias="' + cNewsPost + '"]').trigger('click');
                }, 1000);
            }, 1000);
        } else {
            setTimeout(function() {
                $(opt.elY).find(opt.elYearActivator).eq(0).trigger('click');
                setTimeout(function() {
                    $(opt.elN).find(opt.elNewsActivator).eq(0).trigger('click');
                }, 1000);
            }, 1000);
        }
    };
    
    /**
     * [boot description]
     * @return {[type]} [description]
     */
    that.boot = function() {
        
        opt.yearsToDisplay = $(opt.elY).find('.nsYearActivator').length;
        
        if (opt.yearsToDisplay > opt.maxYearsToDisplay) {
            opt.yearsToDisplay = opt.maxYearsToDisplay;
        }
        opt.yearsToDisplay768 = 2;
        if (opt.yearsToDisplay < 2) {
            opt.yearsToDisplay768 = 1;
        }
        that.bootYears();
    };

    /**
     * [bootYears description]
     * @return {[type]} [description]
     */
    that.bootYears = function() {
        that.yearSlider = $(opt.elY).lightSlider({
            item: opt.yearsToDisplay,
            loop: false,
            slideMove: 1,
            easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
            speed: 600,
            slideMargin: 2,
            pager: false,
            controls: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        item: opt.yearsToDisplay768,
                    }
                }, {
                    breakpoint: 468,
                    settings: {
                        item: 1,
                    }
                }
            ],
            onSliderLoad: function(el) {
                moveControlsOutside(el);
                bindYearLinks(el);
            }
        });
    };
    
    /**
     * [moveControlsOutside description]
     * @param  {[type]} el [description]
     * @return {[type]}    [description]
     */
    var moveControlsOutside = function(el) {
        var $slider = $(el);
        $slider.siblings('.lSAction').appendTo($slider.closest('.lSSlideOuter'));
    };
    
    /**
     * [deactivateYears description]
     * @param  {[type]} el [description]
     * @return {[type]}    [description]
     */
    var deactivateYears = function(el) {
        var $slider = $(el);
        $slider.find(opt.elYearActivator).removeClass('active');
    };
    
    /**
     * [activateYear description]
     * @param  {[type]} $link [description]
     * @return {[type]}       [description]
     */
    var activateYear = function($link) {
        $link.addClass('active');
    };
    
    /**
     * [bindYearLinks description]
     * @param  {[type]} el [description]
     * @return {[type]}    [description]
     */
    var bindYearLinks = function(el) {
        var $slider = $(el);
        $slider.find(opt.elYearActivator).on('click', function(event) {
            var $link = $(this);
            event.preventDefault();
            deactivateYears(el);
            activateYear($link);
            bootYear($link.data('slide-year'));
        });
    };
    
    /**
     * [bootYear description]
     * @param  {[type]} year [description]
     * @return {[type]}      [description]
     */
    var bootYear = function(year) {
        that.currentYear = year;
        var href = '/aktualnosci/' + year;
        $.ajax({
            url: href,
            type: 'GET',
            dataType: 'html',
            data: {},
        }).done(function(data){
            bootNewsFromYear(data);
        });
    };
    
    /**
     * [bootNewsFromYear description]
     * @param  {[type]} data [description]
     * @return {[type]}      [description]
     */
    var bootNewsFromYear = function(data) {
        var newsList = data;
        if (that.newsSlider !== null) {
            that.newsSlider.destroy();
        }
        $(opt.elN).html(newsList);
        that.newsSlider = $(opt.elN).lightSlider({
            item: 4,
            loop: false,
            slideMove: 1,
            easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
            speed: 600,
            slideMargin: 2,
            pager: false,
            controls: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        item: 2,
                    }
                }, {
                    breakpoint: 468,
                    settings: {
                        item: 1,
                    }
                }
            ],
            onSliderLoad: function(el) {
                moveControlsOutside(el);
                bindNewsLinks(el);
            }
        });
    };
    
    /**
     * [bindNewsLinks description]
     * @param  {[type]} el [description]
     * @return {[type]}    [description]
     */
    var bindNewsLinks = function(el) {
        var $slider = $(el);
        $slider.find(opt.elNewsActivator).on('click', function(event) {
            var $link = $(this);
            event.preventDefault();
            deactivateAllNewsLinks(el);
            activateNewsLink($link);
            bootNewsPost($link.data('slide-href'), $link.data('slide-alias'));
        });
    };
    
    /**
     * [deactivateAllNewsLinks description]
     * @param  {[type]} el [description]
     * @return {[type]}    [description]
     */
    var deactivateAllNewsLinks = function(el) {
        var $slider = $(el);
        $slider.find(opt.elNewsActivator).removeClass('active');
    };
    
    /**
     * [activateNewsLink description]
     * @param  {[type]} $link [description]
     * @return {[type]}       [description]
     */
    var activateNewsLink = function($link) {
        $link.addClass('active');
    };
    
    /**
     * [bootNews description]
     * @param  {[type]} href [description]
     * @return {[type]}      [description]
     */
    var bootNewsPost = function(href, alias){
        that.currentNewsPost = alias;
        $.ajax({
            url: href,
            type: 'GET',
            dataType: 'html',
            data: {},
        }).done(function(data){
            displayNewsPost(data);
            opt.urlManipulator.hash = decodeURIComponent(that.currentYear + '-' + that.currentNewsPost);
            document.location.href = opt.urlManipulator;
        });
    };
    
    /**
     * [displayNewsPost description]
     * @param  {[type]} data [description]
     * @return {[type]}      [description]
     */
    var displayNewsPost = function(data) {
        var newsBody = $(data).find(opt.elNewsPost);
        $(opt.elB).html(newsBody);
    };
    
    return that;
    
};
